import React from 'react';
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';

const About = () => {
  return (
    <div className="">
      <Header title="About Us" />
      <div className="containers containerVertical">
        <div className="contentContainer">
          <h2 className="termsHead">About Daimo Bot</h2>
          <p className="grey">
            DAIMO (Decentralized AI Model Optimizer) is a sophisticated crypto trading bot that uses artificial intelligence and machine learning to enhance trading efficiency and profitability in the volatile cryptocurrency market. It analyzes vast amounts of market data in real-time to make informed trading decisions, optimizing entry and exit points to maximize gains and minimize losses. DAIMO’s speed and adaptability provide a significant edge over manual trading strategies.
          </p>
          <div className="space"></div>
          <p className="grey">
            The bot features a user-friendly interface, customizable trading strategies, and detailed performance analytics, making it accessible to both novice and experienced traders. It emphasizes security with robust encryption protocols to protect user data and funds. By automating the trading process, DAIMO democratizes access to advanced trading tools, enabling a wider audience to trade confidently and efficiently in the crypto market.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
