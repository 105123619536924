import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { IoIosNotifications, IoIosPerson } from 'react-icons/io';

const Footer = () => {
  const navigate = useNavigate();
  
  return (
    <div className="footer">
      <div className="footerView1">
        <FaHome className="footerIcon" onClick={() => navigate('/dashboard')} />
      </div>
      <div className="footerView2">
        <IoIosNotifications className="footerIconBold" onClick={() => navigate('/notification')} />
      </div>
      <div className="footerView3">
        <IoIosPerson className="footerIcon" onClick={() => navigate('/profile')} />
      </div>
    </div>
  );
}

export default Footer;