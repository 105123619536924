import axios from "axios";
const Config = {
    baseUrl: 'https://api.daimo.bot/api/',
    //baseUrl: 'http://localhost:7007/api/'
}

const connect = {
    post: async (url, data) => {
        try {
            let token = localStorage.getItem('daimo_token');
            if (token) {
                token = token.replace(/"/g, '');
            }
            const response = await axios.post(`${Config.baseUrl}${url}`, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "post",
            });
            return response;
        } catch (error) {
            console.log('Error in post request:', error.message);
        }
    },
    get: async (url) => {
        try {
            let token = localStorage.getItem('daimo_token');
            if (token) {
                token = token.replace(/"/g, '');
            }
            const response = await axios.get(`${Config.baseUrl}${url}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "get"
            });
            return response;
        } catch (error) {
            console.log('Error in get request:', error.message);
        }
    }
}

export default connect;