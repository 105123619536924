import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { toast } from 'react-toastify'; // React Toastify for toasts
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';
import connect from '../components/Connect';

const ConnectApi = () => {
    const [apiKey, setApiKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchKeys = async () => {
            try {
                const userId = localStorage.getItem('daimo_user');
                const key = await connect.get(`/user/my-keys/${userId}`);
                setApiKey(key.data.key.publicKey);
                setSecretKey(key.data.key.secretKey);
            } catch (err) {
                toast.error('Failed to load API keys', {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
            }
        };
        fetchKeys();
    }, []);

    const handleSubmit = async () => {
        try {
            if (apiKey === '' || secretKey === '') {
                toast.error('Kindly fill in your API keys before proceeding.', {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
                return;
            }
            setIsLoading(true);
            const userId = localStorage.getItem('daimo_user');
            const data = {
                publicKey: apiKey,
                secretKey,
                user_id: userId
            };
            const req = await connect.post('user/saveapi', data);
            setIsLoading(false);
            if (req.data.resCode === 200) {
                toast.success('Thank you for trusting us with your trades, Daimo is happy to see you win!', {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
                navigate('/dashboard');
            } else {
                toast.error(req.data.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
            }
        } catch (err) {
            toast.error(err.message, {
                position: 'bottom-right',
                autoClose: 5000,
            });
            setIsLoading(false);
        }
    };

    return (
        <div style={{ flex: 1 }}>
            <Header title='Connect API Key' />
            <div className="containerVertical contained" style={{ padding: '20px' }}>
                <div className="form-group">
                    <label htmlFor="publicKey" className='inputLabel'>Public Key</label>
                    <input
                        id="publicKey"
                        type="text"
                        placeholder="Enter your API public Key"
                        className="form-control"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="secretKey" className='inputLabel'>Secret Key</label>
                    <input
                        id="secretKey"
                        type="text"
                        placeholder="Enter your API secret Key"
                        className="form-control"
                        value={secretKey}
                        onChange={(e) => setSecretKey(e.target.value)}
                    />
                </div>
                <div className="LoadView">
                    <button className="loginButton" onClick={handleSubmit} disabled={isLoading}>
                        Complete
                    </button>
                    {isLoading && <div className="indicator">Loading...</div>}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ConnectApi;