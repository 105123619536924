import React, { useState, useEffect } from 'react';
import Footer from '../navigation/Footer';
import { Line } from 'react-chartjs-2';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import Subscribe from '../components/Subscribe';
import connect from '../components/Connect';
import { useNavigate } from 'react-router-dom';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const Dashboard = () => {
    const navigate = useNavigate();
    const [dashboard, setDashboard] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
            {
                label: 'Daimo Graph',
                data: [20, 45, 28, 80, 99, 43],
                borderColor: 'rgba(181, 148, 16, 1)', // optional
                fill: false,
                tension: 0.1
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            Legend: {
                position: 'top',
            },
            Tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y} units`;
                        }
                        return label;
                    }
                }
            }
        },
    }

    // Fetch Dashboard data
    useEffect(() => {
        const run = async () => {
            try {
                let user_id = localStorage.getItem('daimo_user');
                if (user_id) {
                    user_id = user_id.replace(/"/g, '');
                    const req = await connect.get(`user/dashboard/${user_id}`);
                    console.log(req.data)
                    const stat = req.status;
                    if (stat === 504) {
                        navigate('/', { replace: true });
                    } else {
                        setDashboard(req.data);
                        setIsLoading(false);
                    }
                } else {
                    navigate('/', { replace: true });
                }
            } catch (err) {
                console.log(err.message);
            }
        };
        run();
    }, [navigate]);

    return (
        <div className="flx">
            <div className="contain">
                <div className="safeArea" />
                <Subscribe />
                <div className="greetingsView">
                    <div>
                        <h1 className="greetUser">Hi {isLoading ? '--' : dashboard.user.short}</h1>
                        <p style={{ color: '#FFF' }}>Welcome back!</p>
                    </div>
                </div>
                <div className="mainView">
                    <div className="leaderboard">
                        <div className="leader">
                            <div className="leadView1">
                                <div className="leadText">Account Holder</div>
                                <div className="leadText2">{isLoading ? '--' : dashboard.user.name}</div>
                            </div>
                            <div className="leadView3">
                                <div className="leadText">Referral Code</div>
                                <div className="leadText2">{isLoading ? '--' : dashboard.user.refcode}</div>
                            </div>
                            <div className="leadView2">
                                <div className="leadText">Referral Bonus</div>
                                <div className="leadText2">${isLoading ? '--' : dashboard.wallet.balance}</div>
                            </div>
                        </div>
                        <div className="space"></div>
                        <button className="withdrawBtn" onClick={() => navigate('/withdrawal')}>
                            <div className="withdrawText">Withdraw</div>
                        </button>
                    </div>
                </div>
                <div className="space"></div>
                <div className='container'>
                    <div className="row">
                        <div className="chartBox col-9">
                            <p className="grey">Trading history</p>
                            <div className="space"></div>
                            <div className="leaderx">
                                <div className="rightx">
                                    <div className="leader">
                                        <p className="left">Profit</p>
                                        <p className="right">+13%</p>
                                    </div>
                                    <p className="grey">25</p>
                                </div>
                                <div className="leftx">
                                    <div className="leader">
                                        <p className="left">Loss</p>
                                        <p className="right-loss">+3%</p>
                                    </div>
                                    <p className="grey">4</p>
                                </div>
                            </div>
                            <div className="space"></div>
                            <Line data={data} options={options} />
                        </div>
                        <div className="chartHist col-3">
                            <div className="rightBox">
                                <p className="rightBoxHead">Total Trade</p>
                                <p className="rightBoxText">95</p>
                            </div>
                            <div className="rightBox">
                                <p className="rightBoxHead">Total Profit</p>
                                <p className="rightBoxText">55%</p>
                            </div>
                            <div className="rightBox">
                                <p className="rightBoxHead">Total Lose</p>
                                <p className="rightBoxText">32%</p>
                            </div>
                            <div className="rightBox">
                                <p className="rightBoxHead">Total Referral</p>
                                <p className="rightBoxText">26</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="space"></div>
                <div className="trnxHist">
                    <div className="tranxView1">
                        <p className="tranxHead">Transactions history</p>
                    </div>
                    <div className="tranxView2">
                        <p className="tranxCont">See all</p>
                    </div>
                </div>
                <div className="space"></div>
                {isLoading ? (
                    <p className="forgotText">---</p>
                ) : (
                    dashboard.transactions.map((item, index) => (
                        <div className="trnxHist" key={index}>
                            <div className="tranxView1">
                                <p className="tranxCont2">{item.createdAt}</p>
                            </div>
                            <div className="tranxView2">
                                <p className="tranxHead2">${item.amount}</p>
                            </div>
                        </div>
                    ))
                )}
                <div className="space"></div>
                <div className="space"></div>
                <div className="space"></div>
                <div className="space"></div>
            </div>
            <Footer />
        </div>
    );
}

export default Dashboard;