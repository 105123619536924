import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowForwardCircle } from 'react-icons/io5';
import characterImage from '../assets/img/character.png'; // Adjust the path if necessary

const Onboarding = () => {
    const navigate = useNavigate();

    return (
        <div className="onboardingContainer">
            <div className="onboardingView">
                <div className="boxText">
                    <p className="onboardingText">
                        Experience profitable and
                    </p>
                    <p className="onboardingText">seamless automated trades with</p>
                    <div className="space"></div><div className="space"></div>
                    <p className="onboardingText2">DAIMO trading BOT</p>
                    <p className="onboardingTextx">Peak Profits, <span className="midText">Prime Trades</span> - Elevate Your Success!</p>
                </div>
                <div className="boxImage">
                    <img
                        src={characterImage}
                        alt="Character"
                    />
                </div>
            </div>
            <div className="onboadingNextView">
                <div className="onboardingButton">
                    <IoArrowForwardCircle className="onboardingIcon" onClick={() => navigate("/login")} />
                </div>
            </div>
        </div>
    );
}

export default Onboarding;
