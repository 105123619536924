import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa'; 

const Header = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className="headerView">
      <FaChevronLeft onClick={() => navigate(-1)} className="goBack" />
      <span className="headerTitle">{title}</span>
    </div>
  );
};

export default Header;