import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; // React Toastify for toasts
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';
import connect from '../components/Connect';

const Support = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState('');

    useEffect(() => {
        const getProfile = async () => {
            try {
                const userId = localStorage.getItem('daimo_user');
                if (userId) {
                    const response = await connect.get(`user/profile/${userId}`);
                    setUser(response.data);
                    setLoading(false);
                } else {
                    // Handle case where userId is not found
                    console.error('User ID not found');
                }
            } catch (err) {
                console.error('Error fetching profile:', err.message);
            }
        };
        getProfile();
    }, []);

    const completeSupport = async () => {
        try {
            const data = {
                name: user?.last_name + ' ' + user?.first_name || '',
                content,
            };
            const response = await connect.post('/user/contact-us', data);
            const snd = response.data;

            if (snd.resCode === 200) {
                toast.success(snd.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
            } else {
                toast.error(snd.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
            }
        } catch (err) {
            toast.error(err.message, {
                position: 'bottom-right',
                autoClose: 5000,
            });
        }
    };

    return (
        <div style={{ flex: 1 }}>
            <Header title="Support" />
            <div className='containerVertical'>
                <div className='contained'>
                    <p className='supportText'>We are glad hearing from you, how may</p>
                    <p className='supportText'>we be of assistance?</p>
                    <div className='space'></div>
                    <div className='space'></div>
                    <label className='inputLabel'>Name</label>
                    <p className='ptext'>{!loading ? user?.last_name + ' ' +user?.first_name : 'Loading...'}</p>
                    <div className='halfSpace'></div>
                    <label className='inputLabel'>Message</label>
                    <div className={{ height: 5 }}></div>
                    <textarea
                        rows={10}
                        className='textarea'
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <div className='space'></div>
                    <div className='space'></div>
                    <div style={{ textAlign: 'center' }}>
                        <button className='loginButton' onClick={completeSupport}>
                            <span className='loginText'>Submit</span>
                        </button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Support;
