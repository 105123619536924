import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { toast } from 'react-toastify'; // React Toastify for toasts
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';
import connect from '../components/Connect';
import myDate from '../components/myDate';

const Notification = () => {
    const [notify, setNotify] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const userId = localStorage.getItem('daimo_user');
                if (userId) {
                    const resp = await connect.get(`user/notification/${userId}`);
                    if (resp) {
                        const data = resp.data;
                        if (data.resCode === 200) {
                            setNotify(data.notifications);
                        } else if (resp.status === 504) {
                            //navigate('/login'); // Redirect to login on 504 error
                        } else {
                            toast.error(data.message, {
                                position: 'bottom-right',
                                autoClose: 5000,
                            });
                        }
                        setIsLoading(false);
                    }
                } else {
                    //navigate('/login'); // Redirect to login if userId is not available
                }
            } catch (err) {
                toast.error(err.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
                setIsLoading(false);
            }
        };
        fetchNotifications();
    }, [navigate]);

    return (
        <div className="container">
            <Header title='Notifications' />
            <div className="containerVertical contained">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    notify.length > 0 ? (
                        notify.map((item, index) => (
                            <div key={index} className="notification-item">
                                <p className="notificationTime">{myDate(item.createdAt)}</p>
                                <div className="notificationView">
                                    <p className="notificationText">{item.notification}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No notifications available.</p>
                    )
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Notification;