import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../assets/css/sub.css';

const Subscribe = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(true);

  const handleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleModalNavigate = () => {
    setModalVisible(!modalVisible);
    navigate('/subscribe');
  };

  return (
    <div className="modalContainer">
      <Modal show={modalVisible} onHide={handleModal} centered>
        <Modal.Header closeButton style={{ fontSize: 14 }}>
          <Modal.Title style={{ fontFamily: 'Poppins-Bold', fontSize: 18 }}>Welcome</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Poppins-Regular', fontSize: 12 }}>
          <p>Subscribe to enjoy the full potential of Daimo</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='withdrawBtn' onClick={handleModalNavigate}>
            <div className='withdrawText'>Subscribe</div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Subscribe;