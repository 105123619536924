import React from 'react';
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';

const Term = () => {

    return (
        <div style={{ flex: 1 }}>
            <Header title="Terms of Service" />
            <div className='containerVertical'>
                <div className=''>
                    <h1 className='termsHead'>Terms of Service for Daimo</h1>
                    <h2 className='termsHeader'>1. Acceptance of Terms</h2>
                    <p className='termsText'>By accessing or using Daimo, you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, you may not use the service.</p>
                    <h2 className='termsHeader'>2. Use of the Service</h2>
                    <p className='termsTextx'>a. Daimo is a tool designed for currency trading purposes. Users are responsible for their own trading decisions and outcomes.</p>
                    <p className='termsTextx'>b. You must be of legal age to use Daimo in your jurisdiction.</p>
                    <p className='termsText'>c. Users are responsible for the security of their login credentials and any activities conducted using their account.</p>
                    <h2 className='termsHeader'>3. Risk Disclosure</h2>
                    <p className='termsTextx'>a. Trading involves significant risk, and past performance does not guarantee future results. Users should only trade with funds they can afford to lose.</p>
                    <p className='termsText'>b. Daimo is not liable for any financial losses incurred by users while using the service.</p>
                    <h2 className='termsHeader'>4. Intellectual Property</h2>
                    <p className='termsTextx'>a. All intellectual property rights related to Daimo, including but not limited to software, logos, and trademarks, are owned by Daimo.</p>
                    <p className='termsText'>b. Users may not reproduce, distribute, or display any part of the service without prior written permission.</p>
                    <h2 className='termsHeader'>5. Service Availability</h2>
                    <p className='termsTextx'>a. Daimo strives to maintain uninterrupted service, but it is not liable for any disruptions, technical issues, or downtime.</p>
                    <p className='termsText'>b. Daimo reserves the right to modify, suspend, or discontinue the service at any time without notice.</p>
                    <h2 className='termsHeader'>6. Privacy Policy</h2>
                    <p className='termsText'>The collection and use of personal information are governed by our Privacy Policy, available here in the app.</p>
                    <h2 className='termsHeader'>7. Disclaimer of Warranty</h2>
                    <p className='termsTextx'>a. Daimo is provided "as is" without any warranties, expressed or implied.</p>
                    <p className='termsText'>b. Daimo does not guarantee the accuracy, completeness, or reliability of the service.</p>
                    <h2 className='termsHeader'>8. Limitation of Liability</h2>
                    <p className='termsText'>Daimo is not liable for any direct, indirect, incidental, consequential, or special damages arising from the use of Daimo.</p>
                    <h2 className='termsHeader'>9. Governing Law</h2>
                    <p className='termsText'>These terms are governed by and construed in accordance with the laws of the financial market. Any disputes arising from or relating to these terms will be subject to the exclusive jurisdiction of the appropriate governing body.</p>
                    <h2 className='termsHeader'>10. Changes to Terms</h2>
                    <p className='termsText'>Daimo reserves the right to modify these terms at any time. Users will be notified of changes, and continued use of the service constitutes acceptance of the updated terms.</p>
                    <h2 className='termsHeader'>11. Contact Information</h2>
                    <p className='termsText'>If you have any questions or concerns regarding these terms, please contact Daimo at hello@daimotrade.com.</p>
                    <div className='space'></div>
                    <div className='space'></div>
                    <div className='space'></div>
                    <div className='space'></div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Term;