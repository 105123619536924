import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { toast } from 'react-toastify'; // React Toastify for toasts
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';
import { Dropdown } from 'react-dropdown-now'; // React Dropdown component for web
import 'react-dropdown-now/style.css'; // Import the stylesheet for Dropdown
import { FaCopy } from 'react-icons/fa'; // React Icons for copy icon
import connect from '../components/Connect';

const Subscribe = () => {
    const [sub, setSub] = useState('$0');
    const [hide, setHide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isIndicate, setIndicate] = useState(false);
    const [wall, setWall] = useState('');
    const [duration, setDuration] = useState(0);
    const [prices, setPrices] = useState([{ label: 'Loading...', value: '$0', duration: 0 }]);
    const navigate = useNavigate();

    useEffect(() => {
        const checkPrice = async () => {
            try {
                const resp = await connect.get('/user/wallet/prices');
                if (resp) {
                    const data = resp.data;
                    if (data.resCode === 200) {
                        setPrices(data.prices);
                    } else {
                        toast.error(data.message, {
                            position: 'bottom-right',
                            autoClose: 5000,
                        });
                    }
                }
            } catch (err) {
                toast.error(err.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
            }
        };
        checkPrice();
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(wall.address);
    };

    const showClickToast = () => {
        toast.error('Kindly select a subscription plan to continue.', {
            position: 'bottom-right',
            autoClose: 5000,
        });
    };

    const completeTranx = async () => {
        if (sub === '$0') {
            showClickToast();
        } else {
            try {
                setIsLoading(true);
                setIndicate(true);
                const user_id = localStorage.getItem('daimo_user');
                if (user_id) {
                    const req = await connect.get(`user/new-address/${user_id}?amount=${sub}&duration=${duration}`);
                    if (req.data.resCode === 200) {
                        setWall(req.data);
                        setIsLoading(false);
                        setHide(false);
                        setIndicate(false);
                        setInterval(() => checkPayment(req.data.uniqueId), 50000);
                    } else {
                        toast.error(req.data.message, {
                            position: 'bottom-right',
                            autoClose: 5000,
                        });
                        setIndicate(false);
                    }
                } else {
                    navigate('/login');
                }
            } catch (err) {
                console.error(err.message);
                setIndicate(false);
            }
        }
    };

    const checkPayment = async (uniqueId) => {
        try {
            const tes = await connect.get(`/user/check/payment/${uniqueId}`);
            const data = tes.data;
            if (data.resCode === 200) {
                navigate('/connect-api');
            }
        } catch (err) {
            // Handle errors if needed
        }
    };

    return (
        <div style={{ flex: 1 }}>
            <Header title="Subscription" />
            <div className="containerVertical contained" style={{ padding: '20px' }}>
                <h2 className='subscriptionHighlight'>Subscription Category</h2>
                <Dropdown
                    options={prices.map(price => ({ label: price.label, value: price.value }))}
                    value={sub}
                    onChange={(item) => {
                        setSub(item.value);
                        const selectedPrice = prices.find(price => price.value === item.value);
                        setDuration(selectedPrice ? selectedPrice.duration : 0);
                        setHide(true);
                    }}
                    placeholder="Select item"
                />
                <p className='subPrice'>${sub}</p>
                <div style={{ textAlign: 'right' }}>
                    <button onClick={() => navigate('/connect-api')}>Skip &raquo;</button>
                </div>
                <div style={{ marginTop: '20px' }}>
                    {!hide && (
                        <div>
                            <h3>Make Transfer</h3>
                            <p>Make Transfer of {sub} (USDT) to the wallet address below on network TRC20.</p>
                        </div>
                    )}
                    {!hide && (
                        <div className="walletView">
                            <h4>Wallet</h4>
                            <p>{isLoading ? '...' : wall.address}</p>
                            <FaCopy onClick={copyToClipboard} />
                        </div>
                    )}
                    {!hide && (
                        <div>
                            <p className="warning">This wallet address is valid for one-time usage</p>
                            <p className="warning">Please do not transfer multiple times.</p>
                            <p className="warning">You can close this window after making payment, transaction confirmation and approval is done automatically.</p>
                        </div>
                    )}
                    {hide && (
                        <div className="LoadView">
                            <button className="loginButton" onClick={completeTranx}>
                                Complete
                            </button>
                            {isIndicate && <div className="indicator">Loading...</div>}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Subscribe;