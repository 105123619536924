import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { toast } from 'react-toastify'; // React Toastify for toasts
import { FaShieldAlt, FaLightbulb, FaPaperPlane, FaMedal, FaUserPlus } from 'react-icons/fa'; // React Icons for web
//import { FaCog, FaShieldAlt, FaLightbulb, FaPaperPlane, FaMedal, FaUserPlus, FaSignOutAlt } from 'react-icons/fa'; // React Icons for web
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';
import connect from '../components/Connect';

const Profile = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [code, setCode] = useState('');

    // const showClickToast = () => {
    //     toast.error('Kindly contact a support agent to update your profile information.', {
    //         position: 'top-right',
    //         autoClose: 5000,
    //     });
    // };

    // const handleLogout = async () => {
    //     try {
    //         // Clear user data from local storage
    //         localStorage.removeItem('daimo_token');
    //         localStorage.removeItem('daimo_user');
    //         navigate('/login');
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // };

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Check out Daimo!',
                    text: `I make use of Daimo for my currency trading and it's been very successful. Join me by using my referral code: ${code}`,
                });
            } else {
                toast.info('Sharing is not supported on this browser.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        } catch (err) {
            toast.error(err.message, {
                position: 'top-right',
                autoClose: 5000,
            });
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const userId = localStorage.getItem('daimo_user');
                if (userId) {
                    const response = await connect.get(`user/profile/${userId}`);
                    console.log(response.data)
                    if (response.status === 504) {
                        navigate('/');
                    } else {
                        setProfile(response.data);
                        setCode(response.data.referral);
                        setIsLoading(false);
                    }
                } else {
                    navigate('/');
                }
            } catch (err) {
                console.error(err.message);
            }
        };
        fetchProfileData();
    }, [navigate]);

    return (
        <div style={{ flex: 1 }}>
            <Header title='Profile' />
            <div className='containers'>
                <div className='generalView'>
                    <div>
                        <label className='inputLabel'>First Name</label>
                        <p className='ptext'>{isLoading ? '--' : profile?.first_name}</p>
                        <div className='halfSpace'></div>
                        <label className='inputLabel'>Last Name</label>
                        <p className='ptext'>{isLoading ? '--' : profile?.last_name}</p>
                        <div className='halfSpace'></div>
                        <label className='inputLabel'>Referral Code</label>
                        <p className='ptext'>{isLoading ? '--' : profile?.referral}</p>
                        <div className='halfSpace'></div>
                        <label className='inputLabel'>Username</label>
                        <p className='ptext'>{isLoading ? '--' : profile?.username}</p>
                        <div className='space'></div>
                    </div>
                    {/* <button className='profileBtn' onClick={showClickToast}>
                        <span className='profileBtnText'>Edit profile</span>
                        <FaCog className='profileBtnIcon' />
                    </button> */}
                    <button className='profileBtn' onClick={() => navigate('/terms')}>
                        <span className='profileBtnText'>Terms of service</span>
                        <FaShieldAlt className='profileBtnIcon' />
                    </button>
                    <button className='profileBtn' onClick={() => navigate('/about')}>
                        <span className='profileBtnText'>About Us</span>
                        <FaLightbulb className='profileBtnIcon' />
                    </button>
                    <button className='profileBtn' onClick={() => navigate('/support')}>
                        <span className='profileBtnText'>Support</span>
                        <FaPaperPlane className='profileBtnIcon' />
                    </button>
                    <button className='profileBtn' onClick={() => navigate('/subscribe')}>
                        <span className='profileBtnText'>Subscription</span>
                        <FaMedal className='profileBtnIcon' />
                    </button>
                    <button className='profileBtn' onClick={handleShare}>
                        <span className='profileBtnText'>Refer a friend</span>
                        <FaUserPlus className='profileBtnIcon' />
                    </button>
                    {/* <button className='profileBtn' onClick={handleLogout}>
                        <span className='profileBtnText'>Logout</span>
                        <FaSignOutAlt className='profileBtnIcon' />
                    </button> */}
                </div>
                <div className='space'></div>
                <div className='space'></div>
                <div className='space'></div>
                <div className='space'></div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;