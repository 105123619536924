import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import About from './screens/About';
import Onboarding from './screens/Onboarding';
import Splash from './screens/Splash';
import Dashboard from './screens/Dashboard';
import Profile from './screens/Profile';
import Term from './screens/Term';
import Support from './screens/Support';
import Subscribe from './screens/Subscribe';
import ConnectApi from './screens/ConnectApi';
import Withdrawal from './screens/Withdrawal';
import Notification from './screens/Notification';

const App = () => {
  return (
    <Router>
      <div>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/about" element={<About />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/terms" element={<Term />} />
          <Route path="/support" element={<Support />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/connect-api" element={<ConnectApi />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/notification" element={<Notification />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
