import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import connect from '../components/Connect';
import { toast } from 'react-toastify'
import characterImage from '../assets/img/character.png'

const Splash = () => {
    const navigate = useNavigate();
    const [ userData, setUserData ] = useState(null)

    useEffect(() => {
        //Check and save userID
        const isLogin = async (props) => {
            try {
                localStorage.removeItem('daimo_token')
                localStorage.removeItem('daimo_user')
                const { id, first_name, last_name, username } = props
                const data = { id, first_name, last_name, username }
                const save = await connect.post('user/gram-login', data)
                const res = save.data
                const { resCode, message, user_id, token } = res
                localStorage.setItem('daimo_token', token)
                localStorage.setItem('daimo_user', user_id)
                if (resCode === 200) {
                    navigate('/dashboard')
                } else {
                    toast.error(message, {
                        position: 'bottom-center',
                        autoClose: 5000,
                    });
                }
            } catch (err) {
                toast.error(err.message, {
                    position: 'bottom-center',
                    autoClose: 5000,
                });
            }
        }
        const tg = window.Telegram.WebApp;
        if (tg) {
            tg.expand()
            // Get user data from Telegram
            const newState = tg.initDataUnsafe?.user;
            if (newState) {
                setUserData(newState)
                isLogin(newState)
            } else {
                toast.error('Network error, Please restart app', {
                    position: 'bottom-center',
                    autoClose: 5000,
                });
            }
            tg.ready();
        }else{}
    }, [navigate]);
    return (
        <div className='body'>
            <div className='onBoardingView'>
                <div className='row'>
                    <div className='col-8'>
                        <p className='onboardingText'>
                            Experience profitable and
                        </p>
                        <p className='onboardingText'>seamless automated trades with</p>
                        <div className='space'></div>
                        <div className='space'></div>
                        <p className='onboardingText2'>DAIMO trading BOT</p>
                        <p className='onboardingTextx'>
                            Peak Profits, <span className='midText'>Prime Trades</span> - Elevate Your Success!
                        </p>
                    </div>
                    <div className='col-4'>
                        <img src={characterImage} alt="Character" />
                    </div>
                    <div className='space'></div>
                    <div className='col-12'>
                        <div className='onboadingNextView'>
                            <div className='onboardingButton'>
                                <div className="spinner">
                                    <FaSpinner className="spinner-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Splash