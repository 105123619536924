import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; // React Toastify for toasts
import Header from '../navigation/Header';
import Footer from '../navigation/Footer';
import connect from '../components/Connect';

const Withdrawal = () => {
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [balance, setBalance] = useState('Loading...');

    useEffect(() => {
        const checkBalance = async () => {
            try {
                const userId = localStorage.getItem('daimo_user');
                const resp = await connect.get(`/user/wallet/balance/${userId}`);
                if (resp) {
                    const data = resp.data;
                    if (data.resCode === 200) {
                        setBalance(data.balance);
                    } else {
                        toast.error(data.message, {
                            position: 'bottom-right',
                            autoClose: 5000,
                        });
                        setBalance('--');
                    }
                }
            } catch (err) {
                toast.error(err.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
                setBalance('--');
            }
        };
        checkBalance();
    }, []);

    const handlePayout = async () => {
        try {
            if (amount === '' || address === '') {
                toast.error('Kindly fill in your withdrawal amount and wallet address.', {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
                return;
            } else if (parseFloat(amount) > parseFloat(balance)) {
                toast.error('Kindly note that your withdrawal cannot be greater than your balance.', {
                    position: 'bottom-right',
                    autoClose: 5000,
                });
                return;
            }
            setIsLoading(true);
            const userId = localStorage.getItem('daimo_user');
            const data = {
                amount,
                address,
                user_id: userId,
            };
            const resp = await connect.post('/user/withdraw', data);
            setIsLoading(false);
            if (resp) {
                const data = resp.data;
                if (data.resCode === 200) {
                    toast.success('Your funds are on their way to your wallet.', {
                        position: 'bottom-right',
                        autoClose: 5000,
                    });
                } else {
                    toast.error(data.message, {
                        position: 'bottom-right',
                        autoClose: 5000,
                    });
                }
            }
        } catch (err) {
            toast.error(err.message, {
                position: 'bottom-right',
                autoClose: 5000,
            });
            setIsLoading(false);
        }
    };

    return (
        <div style={{ flex: 1 }}>
            <Header title='Withdrawal' />
            <div className="containerVertical contained" style={{ padding: '20px' }}>
                <h2>Withdrawal</h2>
                <div className="form-group">
                    <label htmlFor="amount" className='inputLabel'>Amount</label>
                    <input
                        id="amount"
                        type="number"
                        placeholder="Amount in $"
                        className="form-control"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <small className="form-text text-muted">
                        Account(Referral Account balance): <strong>{balance}</strong>
                    </small>
                </div>
                <div className="form-group">
                    <label htmlFor="address" className='inputLabel'>Wallet Address</label>
                    <input
                        id="address"
                        type="text"
                        placeholder="Wallet address"
                        className="form-control"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className="LoadView">
                    <button className="loginButton" onClick={handlePayout} disabled={isLoading}>
                        Process
                    </button>
                    {isLoading && <div className="loading-indicator">Loading...</div>}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Withdrawal;